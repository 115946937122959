import React from 'react'
import Layout from '../layouts'
import SEO from '../components/seo'
import Image from '../components/image'
import Intro from '../components/intro'
import Content from '../components/content'
import Block from '../components/block'
import styled from 'styled-components'
import { fluidRange } from 'polished'
import airbnb from '../images/airbnb.svg'
import hotel from '../images/hotel.svg'
import { animated } from 'react-spring'
import useAnimate from '../hooks/useAnimate'
import P from '../components/paragraph'

const AnimatedBlock = animated(Block)
const AnimatedImage = animated(Image)

const Icon = styled.img`
  margin-top: 20px;
  ${fluidRange(
    {
      prop: 'height',
      fromSize: '80px',
      toSize: '130px'
    },
    '400px',
    '1000px'
  )}
`

const Accommodation = () => {
  const fadeIn = useAnimate()

  return (
    <Layout>
      <SEO title="Accommodation" />
      <Intro>
        <h1>Accommodation</h1>
        <P narrow>
          If you are planning to stay overnight please see below for available options in the local area
        </P>
      </Intro>
      <AnimatedImage style={fadeIn(250)} file="accommodation" />
      <Content css="margin-top: 2em" columns>
        <AnimatedBlock center style={fadeIn(500)}>
          <Icon src={airbnb} alt="Airbnb" />
          <h4>Airbnb</h4>
          <p>
            Airbnb gives you the choice, from renting a room for one to an
            entire house if there is a large group of you for the weekend.
          </p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.airbnb.co.uk/s/Longmoor-Road--Griggs-Green--Liphook-GU30-7PE--UK/homes?refinement_paths%5B%5D=%2Fhomes&search_type=autocomplete_click&tab_id=home_tab&query=Longmoor%20Road%2C%20Griggs%20Green%2C%20Liphook%20GU30%207PE&flexible_trip_lengths%5B%5D=one_week&monthly_start_date=2024-02-01&monthly_length=3&price_filter_input_type=2&price_filter_num_nights=5&channel=EXPLORE&place_id=EjFMb25nbW9vciBSb2FkLCBHcmlnZ3MgR3JlZW4sIExpcGhvb2sgR1UzMCA3UEUsIFVLIi4qLAoUChIJx9KdERE0dEgRZv7KDzASXCgSFAoSCVeJZ_0YNHRIEe35Vongctt4&date_picker_type=calendar&checkin=2024-10-06&checkout=2024-10-07&source=structured_search_input_header"
          >
            View Nearby Airbnbs &rarr;
          </a>
        </AnimatedBlock>
        <AnimatedBlock center style={fadeIn(750)}>
          <Icon src={hotel} alt="Hotel" />
          <h4>Hotels</h4>
          <p>
            If you would rather go the more traditional route, there are several
            hotels in the local area to choose from.
          </p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.google.com/maps/search/hotels/@51.074845,-0.9146343,12z?entry=ttugle.com/maps/search/hotels/@50.920291,-0.6108262,12z/data=!3m1!4b1"
          >
            View Nearby Hotels &rarr;
          </a>
        </AnimatedBlock>
      </Content>
    </Layout>
  )
}

export default Accommodation
